import { flexStyle, sizeStyle } from '@common/constant/commonStyle';
import { themeColor } from '@common/constant/theme';
import {
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import radioIcon from '@assets/icons/common/radio.svg';
import { checkSrc } from '@common/constant/literal';

export type RadioColor =
  | 'red'
  | 'blue'
  | 'blue2'
  | 'purple'
  | 'orange'
  | 'green'
  | 'gray'
  | 'green_600';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  color?: RadioColor;
  /** 전부 채울 건지, 연한 뒷배경 채울 건지 */
  fill?: 'fulfill' | 'light';
  disabled?: boolean;
  children: ReactNode;
}

const Radio = forwardRef(
  (
    {
      color = 'green_600',
      fill = 'fulfill',
      disabled = false,
      children,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (disabled) color = 'gray';
    const theme = {
      color: themeColor[color],
      color_bg: fill === 'light' ? themeColor[`${color}_bg`] : 'white',
      check: fill === 'light' ? checkSrc[color] : radioIcon,
    };

    return (
      <Label $disabled={disabled}>
        <Input
          type="radio"
          $color={theme.color}
          $color_bg={theme.color_bg}
          $check={theme.check}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        <Text className="typo-title-sm">{children}</Text>
      </Label>
    );
  },
);

const Label = styled.label<{ $disabled: boolean }>`
  ${flexStyle()('8px')('center')('center')}
  user-select: none;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
`;
const Input = styled.input<{
  $color: string;
  $color_bg: string;
  $check: string;
}>`
  appearance: none;
  ${sizeStyle('18px')('18px')}
  margin: 0;
  border: 1px solid ${themeColor.green_400};

  border-radius: 30px;
  &:checked {
    background-image: ${props => `url("${props.$check}")`};
    background-size: 75% 75%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: ${props => props.$color_bg};
    border: 1px solid ${props => props.$color};
  }
  &:checked + span {
    color: ${props => props.$color};
  }
  &:not(:checked) + span {
    color: ${props => props.$color};
  }
  &:disabled {
    background-color: white;
    color: ${themeColor.green_200};
    border: 1px solid ${themeColor.green_200};
  }
  &:disabled + span {
    color: ${themeColor.green_200};
  }
`;
const Text = styled.span`
  color: ${themeColor.green_400};
`;

Radio.displayName = 'Radio';
export default Radio;
