export const themeColor: Record<string, string> = {
  contact: '#41CD92',
  atropine: '#FF9900',
  rlrl: '#FF5555',
  spectacles: '#5570FF',
  od: '#2DB0D9',
  os: '#AA6CFF',
  disabled: '#BEC9CF',
  contact_bg: '#EBFFF6',
  atropine_bg: '#FFF5E7',
  rlrl_bg: '#FFEDED',
  spectacles_bg: '#E5EAFF',
  od_bg: '#ECFBFF',
  os_bg: '#F6F0FF',
  disabled_bg: '#EFF2F3',
  green_50: '#EFF2F3',
  green_100: '#DFE4E7',
  green_200: '#BEC9CF',
  green_400: '#7D929E',
  green_500: '#5D7786',
  green_600: '#465965',
  green_600_bg: '#BEC9CF',
  green_700: '#2F3C43',
  navy: '#002548',
  navy_400: '#33516D',
  red: '#FF5555',
  red_bg: '#FFEDED',
  red_modal: '#FFFAFA',
  red_shadow: '0px 0px 20px 8px #FF00001A',
  orange: '#FF9900',
  orange_bg: '#FFF5E7',
  orange_modal: '#FFFCF7',
  orange_shadow: '0px 0px 20px 8px #FF4C001A',
  green: '#41CD92',
  green_bg: '#EBFFF6',
  green_modal: '#F9FFFD',
  green_shadow: '0px 0px 20px 8px #41CD921A',
  blue2: '#5570FF',
  blue2_bg: '#EFF1FF',
  blue: '#2DB0D9',
  blue_bg: '#F5F8FD',
  purple: '#AA6CFF',
  purple_bg: '#F6F0FF',
  gray: '#BEC9CF',
  gray_25: '#E1E6ED',
  gray_bg: '#EFF2F3',
};
