import { SelectHTMLAttributes, useState } from 'react';
import ArrowIcon from '@assets/icons/arrow/arrow_down.svg';
import styled from 'styled-components';
import { themeColor } from '@common/constant/theme';
import { EyeSide } from '@common/type/fundus';
import {
  defaultFont,
  disabledStyle,
  flexStyle,
  fontStyle,
  sizeStyle,
} from '@common/constant/commonStyle';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

interface Props<T extends FieldValues>
  extends SelectHTMLAttributes<HTMLInputElement> {
  name: FieldPath<T>;
  control?: Control<T>;
  array: { displayValue: string; rawValue: string }[];
  placeholder: string;
  setItem: (item: string, type?: EyeSide) => void;
  disabled?: boolean;
  width?: string;
}

const DropdownForForm = <T extends FieldValues>({
  name,
  control,
  array,
  placeholder,
  setItem,
  disabled,
  width = '220px',
}: Props<T>) => {
  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <Wrapper $width={width}>
      <Select
        $disabled={disabled || false}
        onClick={() => {
          !disabled && setHidden(!hidden);
        }}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value } }) => {
            const uiValue =
              array.filter(({ rawValue }) => {
                return value === rawValue;
              })[0]?.displayValue || '';
            return (
              <Input
                $disabled={disabled || false}
                placeholder={placeholder}
                value={uiValue}
                readOnly
              />
            );
          }}
        />
        <Arrow
          $hidden={!hidden}
          $disabled={disabled || false}
          src={ArrowIcon}
        />
      </Select>
      <List hidden={hidden} $width={width}>
        {array.map(({ displayValue, rawValue }) => {
          const key = name + rawValue.replace(/\s/g, '');
          return (
            <ListItem
              key={key}
              onClick={() => {
                setItem(rawValue);
                setHidden(!hidden);
              }}>
              {displayValue}
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default DropdownForForm;

const Wrapper = styled.div<{ $width: string }>`
  ${props => sizeStyle(props.$width)()}
`;

const Select = styled.div<{ $disabled: boolean }>`
  background: white;
  border: 1px solid #e1e6ed;
  padding: 8px 10px;
  ${flexStyle()('8px')('space-between')()}
  cursor: pointer;
  ${props => props.$disabled && disabledStyle}
`;
const Input = styled.input<{ $disabled: boolean; placeholder: string }>`
  ${fontStyle('14px')(600)(20)}
  ${defaultFont}
  ${sizeStyle('100%')()}
  background: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  color: ${themeColor.green_700};
  &::placeholder {
    font-weight: 400;
    color: ${props =>
      props.placeholder.includes('optional')
        ? '#d0cece'
        : themeColor.green_400};
  }

  ${props => props.$disabled && disabledStyle}
`;
const Arrow = styled.img<{ $hidden: boolean; $disabled: boolean }>`
  transform: ${props => props.$hidden && 'scaleY(-1)'};
  opacity: ${props => props.$disabled && '0.5'};
`;
const List = styled.ul<{ $width: string }>`
  position: absolute;
  z-index: 2;
  border: 1px solid #e1e6ed;
  border-top: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  ${props => sizeStyle(props.$width)()}
`;
const ListItem = styled.li`
  list-style-type: none;
  background-color: white;
  padding: 10px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${themeColor.green_50};
  }
`;
