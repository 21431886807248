import { themeColor } from './theme';

export const defaultFont = `
  font-family: Pretendard;
  letter-spacing: -0.25px;
  text-align: left;`;

export const fontStyle =
  (fontSize?: string) => (fontWeight?: number) => (lineHeight?: number) => {
    return [
      !fontSize ? '' : `font-size: ${fontSize};`,
      !fontWeight ? '' : `font-weight: ${fontWeight};`,
      !lineHeight ? '' : `line-height: ${lineHeight}px;`,
    ]
      .filter(Boolean)
      .join(' ');
  };

export const sizeStyle = (width?: string) => (height?: string) => {
  return [!width ? '' : `width: ${width};`, !height ? '' : `height: ${height};`]
    .filter(Boolean)
    .join(' ');
};

export const errorStyle = `
    border: 1px solid ${themeColor.red};
    background: ${themeColor.red_bg};
    input {
        color: ${themeColor.red};
    }
    div {
      color : ${themeColor.red};
    }
`;

export const disabledStyle = `
    cursor: default;
    background: #EFF3FA;
    div {
        color: ${themeColor.green_400}
        border: 1px solid ${themeColor.gray};
    }
    input {
        color: ${themeColor.green_400};
    }
    button {
        border: 1.5px solid ${themeColor.green_200};
        background: ${themeColor.green_50};
    }
`;

export const flexStyle =
  (flexDirection?: string) =>
  (gap?: string) =>
  (justifyContent?: string) =>
  (alignItems?: string) => {
    return [
      `display: flex;`,
      !gap ? '' : `gap: ${gap};`,
      !justifyContent ? '' : `justify-content: ${justifyContent};`,
      !alignItems ? '' : `align-items: ${alignItems};`,
      !flexDirection ? '' : `flex-direction: ${flexDirection};`,
    ]
      .filter(Boolean)
      .join(' ');
  };

export const positionStyle =
  (position?: string) =>
  (top?: string) =>
  (bottom?: string) =>
  (left?: string) =>
  (right?: string) => {
    return [
      !position ? '' : `position: ${position};`,
      !top ? '' : `top: ${top};`,
      !bottom ? '' : `bottom:${bottom};`,
      !left ? '' : `left: ${left};`,
      !right ? '' : `right: ${right};`,
    ]
      .filter(Boolean)
      .join(' ');
  };
