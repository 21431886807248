import { check } from '@assets/icons/check';
import { flexStyle, sizeStyle } from '@common/constant/commonStyle';
import { checkSrc } from '@common/constant/literal';
import { themeColor } from '@common/constant/theme';
import {
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import styled from 'styled-components';

export type CheckboxColor =
  | 'red'
  | 'blue'
  | 'blue2'
  | 'purple'
  | 'orange'
  | 'green'
  | 'gray'
  | 'green_600';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  color?: CheckboxColor;
  /** 전부 채울 건지, 연한 뒷배경 채울 건지 */
  fill?: 'fulfill' | 'light';
  disabled?: boolean;
  children: ReactNode;
}

const Checkbox = forwardRef(
  (
    {
      color = 'green_600',
      fill = 'fulfill',
      disabled = false,
      children,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (disabled) color = 'gray';

    const theme = {
      color: themeColor[color],
      color_bg:
        fill === 'light' ? themeColor[`${color}_bg`] : themeColor[color],
      check: fill === 'light' ? checkSrc[color] : check,
    };

    return (
      <CheckboxLabel $disabled={disabled}>
        <CheckboxInput
          type="checkbox"
          $color={theme.color}
          $color_bg={theme.color_bg}
          $check={theme.check}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        <CheckboxText className="typo-headline-sm">{children}</CheckboxText>
      </CheckboxLabel>
    );
  },
);
Checkbox.displayName = 'Checkbox';

const CheckboxLabel = styled.label<{ $disabled: boolean }>`
  ${flexStyle()('10px')()('center')}
  user-select: none;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
`;
const CheckboxInput = styled.input<{
  $color: string;
  $color_bg: string;
  $check: string;
}>`
  appearance: none;
  ${sizeStyle('1rem')('1rem')}
  border: 1px solid ${props => props.$color};
  border-radius: 3px;
  &:checked {
    background-image: ${props => `url("${props.$check}")`};
    background-size: 80% 80%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: ${props => props.$color_bg};
  }
  &:disabled {
    background-color: ${props => props.$color_bg};
  }
`;
const CheckboxText = styled.span`
  flex: 1;
`;

export default Checkbox;
