import {
  flexStyle,
  fontStyle,
  positionStyle,
  sizeStyle,
} from '@common/constant/commonStyle';
import { themeColor } from '@common/constant/theme';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

/**
 * 마우스 올리면 작은 설명이 뜨는 툴팁입니다
 */
type Props = { color?: string; children: ReactNode };

const Tooltip: React.FC<Props> = ({
  color = themeColor.green_400,
  children,
}: Props) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <Container>
      {visibility && (
        <Bubble>
          <Message>{children}</Message>
          <Point />
          <EmptyPoint />
        </Bubble>
      )}
      <Icon
        $color={color}
        onMouseEnter={() => {
          setVisibility(true);
        }}
        onMouseLeave={() => {
          setVisibility(false);
        }}>
        i
      </Icon>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  ${flexStyle('column')()('center')('center')};
  cursor: default;
`;
const Bubble = styled.div`
  display: block;
  z-index: 1;
  ${positionStyle('absolute')()('18px')()()};
  ${flexStyle('column')()()('center')};
`;
const Message = styled.div`
  ${flexStyle('column')('4px')()('flex-start')};
  background-color: ${themeColor.blue_bg};
  padding: 12px 24px;
  border: 1px solid ${themeColor.green_500};
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(93, 119, 134, 0.3);
`;
const Point = styled.div`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid ${themeColor.green_500};
`;
const EmptyPoint = styled.div`
  z-index: 2;
  ${positionStyle('absolute')()('2px')()()};
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid ${themeColor.blue_bg};
`;

const Icon = styled.div<{ $color: string }>`
  ${sizeStyle('14px')('14px')}
  background-color: ${props => props.$color};
  border: none;
  border-radius: 30px;
  color: white;
  ${fontStyle('10px')(600)()};
  ${flexStyle()()('center')('center')};
`;

export default Tooltip;
